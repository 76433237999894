import React from "react"
import {Auth} from "aws-amplify"

import "./SignInWithFacebook.scss"
import FacebookLogo from "../../images/facebook.svg"


export function SignInWithFacebook(props) {
  return (
    <div
      className="facebook-button"
      onClick={() => Auth.federatedSignIn({provider: "Facebook"})}
    >
      <div className={props.iconClassName ? props.iconClassName : "facebook-button__icon"}><img src={FacebookLogo} width={32} /></div>
      Login with Facebook
    </div>
  )
}


export default SignInWithFacebook
