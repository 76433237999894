import React from "react"
import {Layout} from "antd"
const {Footer} = Layout


export function DefaultFooter() {
  return (
    <Footer className="footer">
      <ul className="footer-list">
        <li className="footer-list__item"><a href='/movies-by-genre'>Movies by Genre</a></li>
        <li className="footer-list__item"><a href='/movies-by-decade'>Movies by Decade</a></li>
        <li className="footer-list__item"><a href='/privacy-policy'>Privacy Policy</a></li>
      </ul>
      &copy; Movie Raker
    </Footer>
  )
}


export default DefaultFooter
