import React from "react"
import {Auth} from "aws-amplify"
import "./App.scss"

import {
  Blockbusterdle,
  HomePage,
  MoviesByDecade,
  MoviesByGenre,
  PrivacyPolicy,
} from "./pages"

import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client"

import {Route, Switch} from "react-router-dom"
import {Layout} from "antd"

import {BlockbusterdleFooter, DefaultFooter} from "./components/Footer"
import {Navigation} from "./components/Navigation"

import link from "./auth/link"
import {UserContextProvider} from "./auth/user"
import TagManager from "react-gtm-module"

const {Content} = Layout


Auth.configure({
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    //scopes: ["email", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.REACT_APP_FACEBOOK_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_FACEBOOK_REDIRECT_SIGN_OUT,
    responseType: "code"
  },
  region: "eu-west-2",
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
})


function App() {
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
  })

  const client = new ApolloClient({
    link: link,
    cache: cache,
  })

  if (process && process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({gtmId: process.env.REACT_APP_GTM_ID})
  }

  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <Layout className="layout">
          <Navigation />
          <Content className="content">
            <div className="App">
              <Switch>
                <Route path="/blockbusterdle" component={Blockbusterdle} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/movies-by-decade" component={MoviesByDecade} />
                <Route path="/movies-by-genre" component={MoviesByGenre} />
                <Route path="/" component={HomePage} />
              </Switch>
            </div>
          </Content>
          <Switch>
            <Route path="/blockbusterdle" component={BlockbusterdleFooter} />
            <Route path="/" component={DefaultFooter} />
          </Switch>
        </Layout>
      </UserContextProvider>
    </ApolloProvider>
  )
}

export default App
