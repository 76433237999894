import React, {useContext, useState} from "react"
import {useLazyQuery} from "@apollo/client"

import {Button, Checkbox, Form, Input, Modal, Slider} from "antd"
import {GET_FRIEND_LIST} from "../../schema/queries"
import {UserContext} from "../../auth/user"
import {getUserCountry} from "../../utils/language"
import {calculateQuerystring} from "../../utils/querystring"
import {getReadableRuntime} from "./MovieList"
import LoginModal from "../Accounts/LoginModal.js"


export function SearchForm(props) {
  const {user} = useContext(UserContext)
  const [isFriendsModalVisible, setIsFriendsModalVisible] = useState(false)
  const [friends, setFriends] = useState([])
  const [friendIds, setFriendIds] = useState([])
  const [friendFilters, setFriendFilters] = useState({})
  const genres = ["Action", "Adventure", "Animation", "Biography", "Comedy", "Crime", "Drama", "Family", "Fantasy", "Film-Noir", "History", "Horror", "Music", "Musical", "Mystery", "Romance", "Sci-Fi", "Sport", "Thriller", "War", "Western"]
  const genreOptions = genres.map((genre) => { return {"label": genre, "value": genre}})
  const userCountry = getUserCountry()
  const certificates = {
    "United Kingdom": ["U", "PG", "12", "12A", "15", "18"],
    "United States": ["G", "PG", "PG-13", "R", "NC-17", "Not Rated", "Unrated"],
  }
  const certificateOptions = certificates[userCountry].map((certificate) => { return {"label": certificate, "value": certificate}})
  const [form] = Form.useForm()

  const [getFriendList, {loading: friendsLoading, error: friendsError, data: friendsData}] = useLazyQuery(GET_FRIEND_LIST, {fetchPolicy: "network-only"})

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false)
  const handleLoginCancel = () => {
    setIsLoginModalVisible(false)
  }

  const showFriendsModal = () => {
    getFriendList()
    setIsFriendsModalVisible(true)
  }

  const handleFriendsOk = () => {
    setIsFriendsModalVisible(false)
    setFriends(friendsData.getFriendList.filter(friend => friendIds.includes(friend.id)))
  }

  const handleFriendsCancel = () => {
    setIsFriendsModalVisible(false)
  }

  const onFriendsChange = (ids) => {
    setFriendIds(ids)
  }

  const onChangeFriendFilter = (friendId, value) => {
    let updatedFriendFilters = friendFilters
    updatedFriendFilters[friendId] = value
    setFriendFilters(updatedFriendFilters)
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let filters = {
          "imdb_score": {"min": values.imdb_score[0], "max": values.imdb_score[1]},
          "popularity": {"min": values.popularity[0], "max": values.popularity[1]},
          "year": {"min": values.year[0], "max": values.year[1]},
          "runtime": {},
          "genre": values.genre,
          "certificates": values.certificate,
        }
        if (values.runtime[0] != 60) {
          filters["runtime"]["min"] = values.runtime[0]
        }
        if (values.runtime[1] != 240) {
          filters["runtime"]["max"] = values.runtime[1]
        }
        if (friendFilters) {
          filters["friends"] = Object.keys(friendFilters).map((key) => {return {"id": key, "values": friendFilters[key]}})
        }
        if (values.text && values.text.trim() != "") {
          filters["text"] = values.text.trim()
        }
        props.refetch({
          "filters": filters,
          "certificate_country": userCountry,
          "sort_by": props.sortBy,
          "offset": 0,
          "limit": props.limit,
        })

        window.history.replaceState(null, "", "/movies?" + calculateQuerystring(values))
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo)
      })

  }

  let friendsOptions = []
  if (friendsData) {
    friendsOptions = friendsData.getFriendList.map((friend) => {
      return {
        "label": <>{friend.name}<img src={"https://graph.facebook.com/" + friend.facebook_id + "/picture?type=square"} /></>,
        "value": friend.id
      }
    })
  }

  const seenOptions = [
    {label: "Seen", value: "SEEN"},
    {label: "Not seen", value: "NOT_SEEN"},
    {label: "Watchlist", value: "WATCHLIST"},
  ]

  const runtimeFormatter = (value) => getReadableRuntime(value)

  const currentYear = new Date().getFullYear()

  return (
    <Form
      form={form}
      layout="vertical"
      style={{maxWidth: 600}}
      initialValues={props.initialValues}
      className="search-form"
    >
      <Form.Item label="IMDb Score" name="imdb_score">
        <Slider
          range
          min={0}
          max={10}
          step={0.1}
          marks={{0: "0", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6", 7: "7", 8: "8", 9: "9", 10: "10"}}
        />
      </Form.Item>
      <Form.Item label="Popularity" name="popularity">
        <Slider
          range
          min={0}
          max={100}
          step={1}
          marks={{0: "0", 10: "10", 20: "20", 30: "30", 40: "40", 50: "50", 60: "60", 70: "70", 80: "80", 90: "90", 100: "100"}}
        />
      </Form.Item>
      <Form.Item label="Year" name="year">
        <Slider
          range
          min={1915}
          max={currentYear}
          step={1}
          marks={{
            1920: "1920", 1930: "1930", 1940: "1940", 1950: "1950", 1960: "1960",
            1970: "1970", 1980: "1980", 1990: "1990", 2000: "2000", 2010: "2010",
            2020: "2020"
          }}
        />
      </Form.Item>

      <Form.Item label="Runtime" name="runtime">
        <Slider
          range
          min={60}
          max={240}
          step={1}
          tooltip={{formatter: runtimeFormatter}}
          marks={{
            60: "1h", 120: "2h", 180: "3h", 240: "4h"
          }}
        />
      </Form.Item>

      <Form.Item label="Genre" name="genre">
        <Checkbox.Group options={genreOptions} className="genre-filters" />
      </Form.Item>

      <Form.Item label="Certificate" name="certificate">
        <Checkbox.Group options={certificateOptions} className="certificate-filters" />
      </Form.Item>

      <Form.Item label="Search terms" name="text">
        <Input />
      </Form.Item>

      {user ? (
        <Form.Item label="Friends">
          <span className="friend-filter-name">You</span>
          <Checkbox.Group options={seenOptions} className="friend-checkboxes" onChange={(values)=>{
            onChangeFriendFilter("me", values)
          }} />
          {friends.map((friend) => {
            return (
              <div key={friend.id}>
                <span className="friend-filter-name">{friend.name}</span>
                <Checkbox.Group options={seenOptions} className="friend-checkboxes" onChange={(values)=>{
                  onChangeFriendFilter(friend.id, values)
                }} />
              </div>
            )
          })}
          <Button onClick={showFriendsModal}>Add friends</Button>
          <Modal title="Add friends" open={isFriendsModalVisible} onOk={handleFriendsOk} onCancel={handleFriendsCancel}>
            {friendsLoading ? (
              <p>Loading friends</p>
            ) : friendsError ? (
              <p>Error loading friends</p>
            ) : friendsData && friendsData.getFriendList.length > 0 ? (
              <div>
                <Checkbox.Group options={friendsOptions} onChange={onFriendsChange} className="friends-list" />
              </div>
            ) : (
              <p>None of your friends are using Movie Raker yet. Spread the word!</p>
            )}
          </Modal>
        </Form.Item>
      ) : (
        <Form.Item label="Friends">
          <span className="friend-filter-name">You</span>
          <Checkbox.Group options={seenOptions} disabled className="friend-checkboxes" onClick={()=>{
            setIsLoginModalVisible(true)
          }} />

          <Button onClick={()=>{setIsLoginModalVisible(true)}}>Add friends</Button>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" onClick={handleSubmit} className="search-form__submit">Search</Button>
      </Form.Item>
      <LoginModal isVisible={isLoginModalVisible} onCancel={handleLoginCancel} />
    </Form>
  )
}

export default SearchForm
