import React from "react"
import {List} from "antd"

import "./NavigationItem.scss"


export function NavigationItem(props) {
  let className = "navbar__item"
  if (props.onClick) className += " navbar__item--clickable"

  return (
    <List.Item
      className={className}
      key="{props.key}"
      onClick={props.onClick}
    >
      <div className="navbar__icon">{props.icon}</div>
      {props.content}
    </List.Item>
  )
}

export default NavigationItem
