import React from "react"
import {Layout} from "antd"
const {Footer} = Layout


export function BlockbusterdleFooter() {
  return (
    <Footer className="footer">
      <a href='/'>Discover Great Movies</a>
      <br/>
      &copy; Movie Raker
    </Footer>
  )
}


export default BlockbusterdleFooter
