import React, {useContext, useState} from "react"
import {useMutation} from "@apollo/client"
import {Button, Space} from "antd"
import {
  MARK_MOVIE_AS_SEEN,
  MARK_MOVIE_AS_NOT_SEEN,
  ADD_MOVIE_TO_WATCHLIST,
  REMOVE_MOVIE_FROM_WATCHLIST,
} from "../../schema/mutations"
import {UserContext} from "../../auth/user"
import LoginModal from "../Accounts/LoginModal.js"
import PlaceholderImage from "../../images/placeholder.png"

import "./MovieList.scss"


export function getReadableRuntime(totalMinutes) {
  var hours = Math.floor(totalMinutes / 60)
  var minutes = totalMinutes % 60
  return hours + "h" + ("00" + minutes).slice(-2)
}


function getCertificateCSSClass(certificate) {
  const language = window.navigator.userLanguage || window.navigator.language
  if (language === "en-GB" && ["U", "PG", "12", "12A", "15", "18"].includes(certificate)) { return "certificate--uk-" + certificate }
  if (language === "en-US" && ["G", "PG", "PG-13", "R", "NC-17"].includes(certificate)) { return "certificate--us-" + certificate }
  return "certificate--default"
}


export function MovieList(props) {
  const {user} = useContext(UserContext)
  const [markMovieAsSeen] = useMutation(MARK_MOVIE_AS_SEEN)
  const [markMovieAsNotSeen] = useMutation(MARK_MOVIE_AS_NOT_SEEN)
  const [addMovieToWatchlist] = useMutation(ADD_MOVIE_TO_WATCHLIST)
  const [removeMovieFromWatchlist] = useMutation(REMOVE_MOVIE_FROM_WATCHLIST)
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false)
  const handleLoginCancel = () => {
    setIsLoginModalVisible(false)
  }

  return (
    <>
      <ul className="movie-list">
        {
          props.movies.map((movie) => {
            return (
              <li key={movie.id} className="movie movie-list__item" itemScope itemType="http://schema.org/Movie">
                <div className="movie__poster-container">
                  <img
                    className="movie__poster"
                    alt="Poster"
                    itemProp="image"
                    src={movie.poster_image ? (
                      process.env.REACT_APP_MEDIA_URL + movie.poster_image
                    ) : (
                      PlaceholderImage
                    )}
                  />
                </div>
                <div className="movie__content">
                  <h2 className="movie__title">
                    <span itemProp="name">{movie.title}</span> <span className="movie__year">({movie.year})</span>
                  </h2>
                  <div className="movie__info">
                    {movie.certificate ? (
                      <div className={"movie__certificate certificate " + getCertificateCSSClass(movie.certificate)} itemProp="contentRating">{movie.certificate}</div>
                    ) : (
                      null
                    )}
                    <div className="movie__genre" itemProp="genre">{movie.genre.join(", ")}</div>
                    <div className="movie__runtime" itemProp="duration">
                      {getReadableRuntime(movie.runtime)}
                    </div>
                  </div>

                  <div className="movie__scores">
                    <a className="movie__imdb-score" href={"https://www.imdb.com/title/" + movie.imdb_id + "/"} itemProp="aggregateRating">{(movie.imdb_score/10).toFixed(1)}</a>

                    {movie.rt_id && movie.rt_critics_score ? (
                      <a className={
                        "movie__rt-critics-score"
                        + (movie.rt_fresh ? " movie__rt-critics-score--fresh" : "")
                        + (movie.rt_rotten ? " movie__rt-critics-score--rotten" : "")
                      } href={"https://www.rottentomatoes.com/m/" + movie.rt_id + "/"} itemProp="aggregateRating">{movie.rt_critics_score}%</a>
                    ) : (
                      null
                    )}

                    {movie.rt_id && movie.rt_audience_score ? (
                      <a className={
                        "movie__rt-audience-score"
                        + (movie.rt_upright ? " movie__rt-audience-score--upright" : "")
                        + (movie.rt_spilled ? " movie__rt-audience-score--spilled" : "")
                      } href={"https://www.rottentomatoes.com/m/" + movie.rt_id + "/"} itemProp="aggregateRating">{movie.rt_audience_score}%</a>
                    ) : (
                      null
                    )}
                  </div>

                  <p className="movie__synopsis" itemProp="description">{movie.synopsis}</p>
                  <dl className="data-list">
                    <dt className="data-list__key">Director</dt>
                    <dd className="data-list__value">
                      <ul className="movie__directors">
                        {movie.directors.map((director) => {
                          return (
                            <li key={director.id} className="movie__directors-item"><a href={"https://www.imdb.com/name/" + director.id + "/"} itemProp="director" itemScope itemType="http://schema.org/Person">{director.name}</a></li>
                          )
                        })}
                      </ul>
                    </dd>
                    <dt className="data-list__key">Cast</dt>
                    <dd className="data-list__value">
                      <ul className="movie__cast">
                        {movie.cast.map((actor) => {
                          return (
                            <li key={actor.id} className="movie__cast-item"><a href={"https://www.imdb.com/name/" + actor.id + "/"} itemProp="actor" itemScope itemType="http://schema.org/Person">{actor.name}</a></li>
                          )
                        })}
                      </ul>
                    </dd>

                    <Space>
                      {user && movie.user_movie.seen ? (
                        <Button className="movie-button movie-button__seen" onClick={()=>{
                          markMovieAsNotSeen({variables: {movie_id: movie.id}})
                        }}>Seen</Button>
                      ) : (
                        <Button className="movie-button movie-button__unseen" onClick={()=>{
                          if (user) {
                            markMovieAsSeen({variables: {movie_id: movie.id}})
                          } else {
                            setIsLoginModalVisible(true)
                          }
                        }}>Mark as seen</Button>
                      )}

                      {user && movie.user_movie.watchlist ? (
                        <Button className="movie-button movie-button__watchlisted" onClick={()=>{
                          removeMovieFromWatchlist({variables: {movie_id: movie.id}})
                        }}>On watchlist</Button>
                      ) : (
                        <Button className="movie-button movie-button__unwatchlisted" onClick={()=>{
                          if (user) {
                            addMovieToWatchlist({variables: {movie_id: movie.id}})
                          } else {
                            setIsLoginModalVisible(true)
                          }
                        }}>Add to watchlist</Button>
                      )}
                    </Space>

                  </dl>
                </div>
              </li>
            )
          })
        }
      </ul>
      <LoginModal isVisible={isLoginModalVisible} onCancel={handleLoginCancel} />
    </>
  )
}


export default MovieList
