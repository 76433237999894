import qs from "qs"


export const calculateQuerystring = (values) => {
  let qsData = {...values}
  const currentYear = new Date().getFullYear()
  const minimumYear = 1915

  if (qsData["imdb_score"]) {
    qsData["imdb_score"] = values["imdb_score"][0] + "-" + values["imdb_score"][1]
  }
  if (qsData["imdb_score"]) {
    qsData["popularity"] = values["popularity"][0] + "-" + values["popularity"][1]
  }
  if (qsData["imdb_score"]) {
    qsData["runtime"] = values["runtime"][0] + "-" + values["runtime"][1]
  }

  if (qsData["year"]) {
    let minYear = parseInt(values["year"][0])
    let maxYear = parseInt(values["year"][1])

    if (minYear == maxYear) {
      qsData["year"] = minYear
    } else {
      if (maxYear == currentYear) maxYear = ""
      if (minYear == minimumYear) minYear = ""
      if (minYear || maxYear) {
        qsData["year"] = minYear + "-" + maxYear
      } else {
        delete qsData["year"]
      }
    }
  }
  return qs.stringify(qsData, {arrayFormat: "comma", encode: false})
}
