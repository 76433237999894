import React from "react"


export function MoviesByDecade() {
  const decades = [2020, 2010, 2000, 1990, 1980, 1970, 1960, 1950, 1940, 1930, 1920, 1910]
  const genres = ["Action", "Adventure", "Animation", "Biography", "Comedy", "Crime", "Drama", "Family", "Fantasy", "Film-Noir", "History", "Horror", "Music", "Musical", "Mystery", "Romance", "Sci-Fi", "Sport", "Thriller", "War", "Western"]

  return (
    <div className="site-layout-content">
      <h1>Movies by Decade</h1>
      <ul className="decade-list">
        <li key="all-time" className="all-time">
          <a href={"/movies"}>Best Movies of All Time</a>
          <ul className="genre-list">
            {genres.map((genre) => {
              return (
                <li key={genre} className="genre">
                  <a href={"/movies?genre=" + genre}>Best {genre} Movies of All Time</a>
                </li>
              )
            })}
          </ul>
        </li>
        <li key="21-century" className="century">
          <a href={"/movies?year=2000-"}>Best Movies of the 21st Century</a>
          <ul className="genre-list">
            {genres.map((genre) => {
              return (
                <li key={genre} className="genre">
                  <a href={"/movies?year=2000-&genre=" + genre}>Best {genre} Movies of the 21st Century</a>
                </li>
              )
            })}
          </ul>
        </li>
        <li key="20-century" className="century">
          <a href={"/movies?year=1900-1999"}>Best Movies of the 20th Century</a>
          <ul className="genre-list">
            {genres.map((genre) => {
              return (
                <li key={genre} className="genre">
                  <a href={"/movies?year=1900-1999&genre=" + genre}>Best {genre} Movies of the 20th Century</a>
                </li>
              )
            })}
          </ul>
        </li>
        {
          decades.map((decade) => {
            return (
              <li key={decade} className="decade">
                <a href={"/movies?year=" + decade + "-" + (decade == 2020 ? "": decade + 9)}>Best Movies of the {decade}s</a>
                <ul className="genre-list">
                  {genres.map((genre) => {
                    return (
                      <li key={genre} className="genre">
                        <a href={"/movies?year=" + decade + "-" + (decade == 2020 ? "": decade + 9) + "&genre=" + genre}>Best {genre} Movies of the {decade}s</a>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })
        }
      </ul>
    </div>
  )

}
