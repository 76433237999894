import {gql} from "@apollo/client"

export const GET_CURRENT_USER = null


export const GET_FRIEND_LIST = gql`
  query getFriendList {
    getFriendList {
      id
      facebook_id
      name
    }
  }
`


export const GET_MOVIE_LIST = gql`
  query getMovieList($certificate_country: String, $filters: MovieFilters, $sort_by: SortBy, $limit: Int, $offset: Int) {
    getMovieList(certificate_country: $certificate_country, filters: $filters, sort_by: $sort_by, limit: $limit, offset: $offset) {
      id
      imdb_id
      rt_id
      title
      year
      poster_image
      imdb_score
      rt_critics_score
      rt_fresh
      rt_rotten
      rt_audience_score
      rt_upright
      rt_spilled
      genre
      synopsis
      runtime
      certificate
      directors {
        id
        name
      }
      cast {
        id
        name
      }
      user_movie {
        id
        seen
        watchlist
      }
    }
  }
`


export const GET_DAILY_MOVIE = gql`
  query getDailyMovie($local_date: String) {
    getDailyMovie(local_date: $local_date) {
      id
      title
      year
      poster_image
      genre
      runtime
      directors {
        id
        name
      }
      cast {
        id
        name
      }
      accepted_answers
    }
  }
`
