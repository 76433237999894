import {gql} from "@apollo/client"


export const ADD_FRIEND_QUEUE_ITEM = gql`
  mutation addFriendQueueItem($facebook_id: String!, $friend_data: [FacebookUserInput]) {
    addFriendQueueItem(facebook_id: $facebook_id, friend_data: $friend_data) {
      facebook_id
      friend_data {
        id
        name
      }
    }
  }
`

export const MARK_MOVIE_AS_SEEN = gql`
  mutation markMovieAsSeen($movie_id: String!) {
    markMovieAsSeen(movie_id: $movie_id) {
      id
      seen
    }
  }
`

export const MARK_MOVIE_AS_NOT_SEEN = gql`
  mutation markMovieAsNotSeen($movie_id: String!) {
    markMovieAsNotSeen(movie_id: $movie_id) {
      id
      seen
    }
  }
`

export const ADD_MOVIE_TO_WATCHLIST = gql`
  mutation addMovieToWatchlist($movie_id: String!) {
    addMovieToWatchlist(movie_id: $movie_id) {
      id
      watchlist
    }
  }
`

export const REMOVE_MOVIE_FROM_WATCHLIST = gql`
  mutation removeMovieFromWatchlist($movie_id: String!) {
    removeMovieFromWatchlist(movie_id: $movie_id) {
      id
      watchlist
    }
  }
`
