import {ApolloLink} from "@apollo/client"
import {createHttpLink} from "@apollo/client"
import {RetryLink} from "@apollo/client/link/retry"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"
import {fromPromise} from "@apollo/client"

import {AUTH_TYPE} from "aws-appsync"
import {createAuthLink} from "aws-appsync-auth-link"
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link"


import {Amplify, Auth} from "aws-amplify"


Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "eu-west-2",
    userPoolId:  process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId:  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
})

const url = process.env.REACT_APP_API_URL

const region = "eu-west-2"

const httpLink = createHttpLink({uri: url})

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 50,
    retryIf: (error) => !!error
  }
})

let amplifyAuthLink = null


const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
  if (graphQLErrors)
    graphQLErrors.map(({message, locations, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)

  if (networkError?.name === "ServerError" && networkError?.statusCode === 401) {
    return fromPromise(
      Auth.currentSession().then((session) => {
        amplifyAuthLink = createCognitoAuthLink(session)
        const headers = operation.getContext().headers

        operation.setContext({
          headers: {
            ...headers,
            Authorization: session.getIdToken().getJwtToken()
          }
        })
      }).catch((error) => {
        console.log(error)
      })
    ).flatMap(() => {
      return forward(operation)
    })
  } else {
    return forward(operation)
  }
})


const createIAMAuthLink = () =>
  createAuthLink({
    auth: {
      credentials: () => Auth.currentCredentials(),
      type: AUTH_TYPE.AWS_IAM,
    },
    region,
    url,
  })


const createCognitoAuthLink = (session) =>
  createAuthLink({
    auth: {
      jwtToken: session.getIdToken().getJwtToken(),
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    },
    region,
    url,
  })


const cachedAmplifyAuthLink = setContext(() => {
  if (amplifyAuthLink) {
    console.log("got cached auth link")
    return {amplifyAuthLink}
  }

  console.log("getting current session")
  return Auth.currentSession()
    .then((session) => {
      amplifyAuthLink = createCognitoAuthLink(session)
      return {amplifyAuthLink}
    })
    .catch((error) => {
      console.log(error)
      amplifyAuthLink = createIAMAuthLink()
      return {amplifyAuthLink}
    })
})


const getAuthLink = () => {
  return cachedAmplifyAuthLink.concat(
    new ApolloLink((operation, forward) =>
      operation.getContext().amplifyAuthLink.request(operation, forward)
    )
  )
}


const link = ApolloLink.from([
  retryLink,
  errorLink,
  getAuthLink(),
  createSubscriptionHandshakeLink(url, httpLink)
])


export default link
