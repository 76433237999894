import React, {useState, useEffect} from "react"
import {Auth} from "aws-amplify"

export const UserContext = React.createContext()


export function UserContextProvider(props) {
  const [user, setUser] = useState()
  const [userError, setUserError] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user)
      })
      .catch((error) => {
        console.log(error)
        setUserError(true)
      })
  }, [])

  let value = {
    user: user,
    userError: userError,
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
