import React from "react"

import {Select, Space} from "antd"


export function SortBox(props) {
  function handleChange(value) {
    let order = "ASC"

    if (value.startsWith("-")) {
      order = "DESC"
      value = value.substring(1)
    }

    const sortBy = {"field": value, "order": order}
    props.setSortBy(sortBy)
    props.refetch({
      "sort_by": sortBy,
      "offset": 0,
      "limit": props.limit,
    })
  }

  return (
    <Space>
      Sort by
      <Select defaultValue="-imdb_score" onChange={handleChange} style={{"minWidth": "200px"}}>
        <Select.Option value="-imdb_score">Rating (High to Low)</Select.Option>
        <Select.Option value="imdb_score">Rating (Low to High)</Select.Option>
        <Select.Option value="-popularity">Popularity (High to Low)</Select.Option>
        <Select.Option value="popularity">Popularity (Low to High)</Select.Option>
        <Select.Option value="-year">Year (Newest first)</Select.Option>
        <Select.Option value="year">Year (Oldest first)</Select.Option>
        <Select.Option value="title">Title (A-Z)</Select.Option>
        <Select.Option value="-title">Title (Z-A)</Select.Option>
      </Select>
    </Space>
  )
}


export default SortBox
