async function initFacebookSDK() {
  if (!window.FB) createScript().then((fb) => {
    return fb
  })
  return window.FB
}


async function createScript() {
  // load the sdk
  window.fbAsyncInit = fbAsyncInit
  const script = document.createElement("script")
  script.src = "https://connect.facebook.net/en_US/sdk.js"
  script.async = true
  script.onload = initFB
  document.body.appendChild(script)
  return window.FB
}

function initFB() {

}

function fbAsyncInit() {
  // init the fb sdk client
  const fb = window.FB
  fb.init({
    appId   : process.env.REACT_APP_FACEBOOK_APP_ID,
    cookie  : true,
    xfbml   : true,
    version : "v2.11"
  })
}


export default initFacebookSDK
